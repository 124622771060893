import React, { Component } from 'react'
import LazyComponent from './lazy_component'

export default class CancelButtonLbTrade extends LazyComponent {
  onSubmit(event) {
    if(!confirm("Está seguro que desea anular este trade?")){
      event.preventDefault();
    }
  }

  render() {
    var trade = this.props.lb_trade;
    if (trade.aasm_state == 'registrado'){
      return(
        <form action={'/lb_trades/'+trade.id+'/cancel'} method='post'>
          <input type='hidden' name='authenticity_token' value={this.props.csrfToken} />
          <button  className="btn btn-default btn-xs" type="submit">
            <span className="glyphicon glyphicon-ban-circle"></span>
            &nbsp;Anular
          </button>
        </form>
      )
    }else{
      return null;
    }
  }
}
