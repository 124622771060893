import React, { Component } from 'react'
import LazyComponent from './lazy_component'

export default class FieldWrapper extends LazyComponent {
  render() {
    var errors = this.props.errors || [];
    errors = errors.join(', ');
    if(errors.length > 0){
      return (
        <div>
          <div className="field_with_errors">
            {this.props.children}
          </div>
          <div className='has-error'>
            <span className='help-block'>{errors}</span>
          </div>
        </div>
      );
    }else{
      return (
        <div>
          {this.props.children}
        </div>
      );
    }
  }
};
