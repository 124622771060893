import React, { Component } from 'react'
import FieldWrapper from './field_wrapper'
import LazyComponent from './lazy_component'

export default class InputField extends LazyComponent {
  render(){
    var label = this.props.label || this.props.attribute.label;
    var value = this.props.value || this.props.attribute.value;
    var nameForm = this.props.nameForm || this.props.attribute.name_form;
    var pattern = this.props.pattern || this.props.attribute.pattern;
    var title = this.props.title || this.props.attribute.title;
    var placeholder = this.props.placeholder || this.props.attribute.placeholder;
    var maxLength = this.props.maxLength || this.props.attribute.max_length;
    var className = this.props.className || 'form-control';
    var errors = this.props.errors || this.props.attribute.errors;
    var require = this.props.required || this.props.attribute.required || 'true';

    return(
      <div className='form-group'>
        <FieldWrapper errors={errors}>
          <label className='required'>{label}</label>
          <input type='text'
                   name={nameForm}
                   className={className}
                   pattern={pattern}
                   title={title}
                   defaultValue={value}
                   placeholder={placeholder}
                   maxLength={maxLength} />

          {this.drawHelpText()}
        </FieldWrapper>
      </div>
    )
  }
}

