import React, { Component } from 'react'
import InputField from './input_field'
import SelectField from './select_field'
import AccountField from './account_field'

export default class EuropeanForm extends Component {
  render() {
    return(
      <div>
        <InputField attribute={this.props.holder_name} />

        <InputField attribute={this.props.holder_email} />

        <InputField attribute={this.props.holder_ci} label='Cédula de identidad'
                    placeholder='V12345678 ó E3244325' />

        <SelectField attribute={this.props.bank_id}
                     options={this.props.venezuelan_banks}
                     helpText='Tenemos transferencias directas a: Banesco. Para los otros bancos la transferencia se hará efectiva en su cuenta el día hábil siguiente. ' />

        <SelectField attribute={this.props.account_type}
                     options={['Ahorro', 'Corriente']} />

        <AccountField attribute={this.props.account_number}
                    maxLength='20'/>

        <SelectField attribute={this.props.bank_receptor_id}
             label='Método de pago'
             options={this.props.avaliable_european_bank_receptors}
             helpText='Si eliges un banco podrás hacer una transferencia o depósito. Recuerda que las transferencias entre bancos distintos demoran un día hábil, y si son transferencias entre paises de en UE de 1 a 2 días hábiles. Paypal es instantaneo, pero recuerda usar un método sin comsiones para asegurarte que llegue el monto que necesitas.'/>
      </div>
    )
  }
}
