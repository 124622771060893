import React, { Component } from 'react'
import FieldWrapper from './field_wrapper'
import LazyComponent from './lazy_component'
import NumberFormat from 'react-number-format'

export default class AccountField extends LazyComponent {
  render() {
    var label = this.props.label || this.props.attribute.label;
    var value = this.props.value || this.props.attribute.value || '';
    var nameForm = this.props.nameForm || this.props.attribute.name_form;
    var title = this.props.title || this.props.attribute.title;
    var placeholder = this.props.placeholder || this.props.attribute.placeholder;
    var className = this.props.className || 'form-control';
    var errors = this.props.errors || this.props.attribute.errors;
    var require = this.props.required || this.props.attribute.required || 'true';

    return(
      <div className='form-group'>
        <FieldWrapper errors={errors}>
          <label className='required'>{label}</label>
          <NumberFormat
            name={nameForm}
            className={className}
            value={value}
            placeholder={placeholder}
            format="#### #### #### #### ####"
          />
          {this.drawHelpText()}
        </FieldWrapper>
      </div>
    )
  }
}
