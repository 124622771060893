import React, { Component } from 'react'
import LazyComponent from './lazy_component'
import FieldWrapper from './field_wrapper'

export default class SelectField extends LazyComponent {
  render(){
    var _this = this;
    var label = this.props.label || this.props.attribute.label;
    var value = this.props.value || this.props.attribute.value;
    var nameForm = this.props.nameForm || this.props.attribute.name_form;
    var maxLength = this.props.maxLength || this.props.attribute.max_length;
    var options = this.props.options || this.props.attribute.options || [];
    var className= this.props.className || 'form-control';
    var errors = this.props.errors || this.props.attribute.errors;

    return(
      <div className='form-group'>
        <FieldWrapper errors={errors}>
          <label className='required'>{label}</label>
          <select defaultValue={value} name={nameForm} className={className}>
            <option value=''>Por favor seleccione</option>
            {options.map(function (option, index) {
              value = typeof(option) == 'object' ? option.id : option;
              name = typeof(option) == 'object' ? option.name : option;
              return(
                <option key={index} value={value}>{name}</option>
              )
            })}
          </select>
          {this.drawHelpText()}
        </FieldWrapper>
      </div>
    )
  }
};
