import React, { Component } from 'react'
import InputField from './input_field'
import SelectField from './select_field'
import AccountField from './account_field'

export default class VenezuelanForm extends Component {
  render() {
    return(
      <div>
        <InputField attribute={this.props.holder_name} />

        <InputField attribute={this.props.holder_email} />

        <InputField attribute={this.props.holder_ci} label='Cédula de identidad'
                    placeholder='V12345678 ó E3244325' />

        <SelectField attribute={this.props.bank_id}
                     options={this.props.venezuelan_banks}
                     helpText='Tenemos transferencias directas a: Banesco. Para los otros bancos la transferencia se hará efectiva en su cuenta el día hábil siguiente. ' />

        <SelectField attribute={this.props.account_type}
                     options={['Ahorro', 'Corriente']} />

        <AccountField attribute={this.props.account_number}
                    maxLength='20'
        />
      </div>
    )
  }
};
