import React, { Component } from 'react';

export default class LazyComponent extends Component {
  constructor(props) {
    super(props);
    var foos = Object.getOwnPropertyNames(this.__proto__)
    foos.forEach(function (foo) {
      this[foo] = this[foo].bind(this);
    }.bind(this));
  }

  drawHelpText () {
    var helpText = this.props.helpText || this.props.attribute.help_text;
    if (helpText){
      return(
        <small className='text-danger'>
          {helpText}
        </small>
      )
    }
  }
}
