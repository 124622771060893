import React, { Component } from 'react'
import numeral from 'numeral'
import NumberFormat from 'react-number-format'
import VenezuelanForm from './venezuelan_form'
import EuropeanForm from './european_form'

export default class CalculatorForm extends Component {
  constructor(props) {
    super(props);
    var originAmount = this.props.originAmount.value;
    var rate = this.getRate();
    var originAmount = numeral(originAmount || rate.config.min_amount).value();

    var estrictDestinationAmount = (originAmount * this.getRateValue());
    var destinationAmount = this.props.destinationAmount.value || estrictDestinationAmount;
    var marginal = this.getMarginal(destinationAmount, originAmount);
    if (marginal > 0){
      destinationAmount = (destinationAmount + marginal - estrictDestinationAmount.toFixed(2)) == 0 ? destinationAmount : estrictDestinationAmount;
    }else{
      destinationAmount = estrictDestinationAmount;
    }
    this.state = {
      type: this.props.type.value || 'TransferChlVen',
      destinationAmount: destinationAmount,
      originAmount: originAmount,
    }
  }

  getRate(transferType) {
    var type = this.state ? (transferType || this.state.type) : (this.props.type.value || 'TransferChlVen');
    type = type.replace('Transfer', 'Rate');
    var rate = this.props.rates.find(function(rate){
      if(rate['type'] == type){
        return rate;
      }
    });
    return rate;
  }

  getRateValue() {
    var rate = this.getRate();
    return rate ? rate.config.value : undefined;
  }

  originCurrency(){
    var rate = this.getRate();
    return rate.config.origin_sym;
  }

  minAmount() {
    var rate = this.getRate();
    return rate.config.min_amount;
  }

  minAmountHelpText() {
    return this.originCurrency() + numeral(this.minAmount()).format('0,0[.]');
  }

  updateShowedRate() {
    var tasa = document.getElementById('tasa');
    if (tasa){
      var rate = this.getRate();
      debugger
      var value = numeral(rate.config.value.toDecimalCurrency()).format('0,00.0000') + ' ' + rate.config.sym;
      tasa.innerHTML = value;
    }
  }

  componentDidMount() {
    this.updateShowedRate();
  }

  onChangeOriginAmount(event) {
    var rate = this.getRate();
    var originAmount = numeral(event.target.value.replace(this.originCurrency(), '').trim()).value();
    if (rate.config.is_inverted_value){
      var destinationAmount = originAmount / this.getRateValue();
    }else{
      var destinationAmount = originAmount * this.getRateValue();
    }

    if (!rate.config.destination_decimal){
      destinationAmount = Math.ceil(destinationAmount);
    }else{
      destinationAmount = destinationAmount.toFixedSpecial(2);
    }
    this.setState({originAmount: event.target.value, destinationAmount: destinationAmount});
  }

  onChangeDestinationAmount(event) {
    var rate = this.getRate();
    var destinationAmount = numeral(event.target.value.replace(this.originCurrency(), '').trim()).value();
    if (rate.config.is_inverted_value){
      var originAmount = destinationAmount * this.getRateValue();
    }else{
      var originAmount = destinationAmount / this.getRateValue();
    }
    if (!rate.config.origin_decimal){
      originAmount = Math.ceil(originAmount)
    }else{
      originAmount = originAmount.toDecimalCurrency()
    }
    this.setState({originAmount: originAmount, destinationAmount: event.target.value});
  }

  amountValid(){
    var originAmount = this.state.originAmount;
    var sanitizedOriginAmount = this.sanitizeAmount(originAmount)
    return sanitizedOriginAmount  >= this.minAmount();
  }

  swithSubmitButton() {
    // if (this.amountValid()){
    //   $.rails.enableFormElements($($.rails.formSubmitSelector));
    // }else{
    //   $.rails.disableFormElements($($.rails.formSubmitSelector));
    // }
  }

  drawAmountIconStatus() {
    var glyphicon = this.amountValid() ? 'glyphicon-ok' : 'glyphicon-remove';
    return (
      <span className={"glyphicon " + glyphicon + " form-control-feedback"}
            aria-hidden="true">
      </span>
    )
  }

  getFormGroupClass() {
    var statusFormGroup = this.amountValid() ? 'has-success' : 'has-error';
    return 'form-group '+ statusFormGroup +' has-feedback';
  }

  getMarginal(originAmountrgB, originAmountrgA){
    var destinationAmount = this.state ? this.state.destinationAmount : originAmountrgB;
    var originAmount = this.state ? this.state.originAmount : originAmountrgA;
    var destinationAmount = numeral(destinationAmount).value();
    var originAmount = numeral(originAmount).value();
    var rate = this.getRate();
    if (rate.config.is_inverted_value){
      var estrictDestinationAmount = (originAmount / this.getRateValue());
    }else{
      var estrictDestinationAmount = (originAmount * this.getRateValue());
    }
    return numeral((estrictDestinationAmount - destinationAmount).toFixed(2)).value();
  }

  drawMarginal() {
    var marginal = this.getMarginal();
    var rate = this.getRate();
    if (marginal > 0){
      return (
        <span className="help-block">
          Por la misma cantidad de {rate.config.origin_sym} podrías transferir&nbsp;
          <a style={{cursor: 'pointer'}} onClick={this.sumMarginal}>
            {rate.config.destination_sym} {marginal} más
          </a>
        </span>
      )
    }
  }

  sumMarginal(){
    var rate = this.getRate();
    var originAmount = this.sanitizeAmount(this.state.originAmount);
    if (rate.config.is_inverted_value){
      var destinationAmount = this.sanitizeAmount(originAmount / rate.config.value).toDecimalCurrency();
    }else{
      var destinationAmount = this.sanitizeAmount(originAmount * rate.config.value).toDecimalCurrency();
    }
    this.setState({destinationAmount: destinationAmount})
  }

  onChangeCardinality(event) {
    event.persist();
    var _this = this;
    var rate = this.getRate(event.target.value);
    var newOriginAmount = rate.config.min_amount;
    if (rate.config.is_inverted_value){
      var newDestinationAmount = newOriginAmount / rate.config.value ;
    }else{
      var newDestinationAmount = newOriginAmount *  rate.config.value;
    }
    this.setState({type: event.target.value, originAmount: newOriginAmount, destinationAmount: newDestinationAmount}, function () {
      var header = document.getElementById('transfer-data-header');
      if (header){
        header.innerHTML = event.target.options[event.target.selectedIndex].dataset.bankHeader ;
      }
      _this.updateShowedRate();
    });
  }

  drawCardinality() {
    return(
      <div className='form-group'>
        <label className='control-label required'>Tipo de transferencia</label>
        <select defaultValue={this.state.type}
                name={this.props.type.name}
                className='form-control'
                onChange={this.onChangeCardinality.bind(this)}>

          {this.props.rates.map(function(rate, index){
            var value = rate['type'].replace('Rate', 'Transfer');
            var label = rate['transfer_type'];
            var transfer_data_bank_header = rate['transfer_data_bank_header']
            return(
              <option key={'cardinality-option-' + index}
                      value={value}
                      data-bank-header={transfer_data_bank_header}>{label}</option>
            )
          })}
        </select>
      </div>
    )
  }

  drawInputdestinationAmount(){
    var rate = this.getRate();
    var decimalScale = rate.config.destination_decimal ? 2 : 0;
    var destinationAmount = this.state.destinationAmount;
    return (
      <div className={this.getFormGroupClass()}>
        <label className='control-label required' htmlFor="inputoriginAmount">{this.getLabelDestinationAmount()}</label>
        <NumberFormat
          value={destinationAmount}
          thousandSeparator={true}
          className='form-control'
          prefix={rate.config.destination_sym}
          decimalScale={decimalScale}
          onChange={this.onChangeDestinationAmount.bind(this)}
        />
        <input type='hidden' value={this.sanitizeAmount(destinationAmount)} name={this.props.destinationAmount.name} />
        {this.drawAmountIconStatus()}
        {/*this.drawMarginal()*/}
      </div>
    )
  }

  drawInputoriginAmount() {
    var rate = this.getRate();
    var decimalScale = rate.config.origin_decimal ? 2 : 0;
    var originAmount = this.state.originAmount;

    return (
      <div className={this.getFormGroupClass()}>
        <label className='control-label required' htmlFor="inputoriginAmount">{this.getLabelOriginAmount()}</label>
        <NumberFormat
          value={originAmount}
          thousandSeparator={true}
          className='form-control'
          prefix={rate.config.origin_sym}
          decimalScale={decimalScale}
          onChange={this.onChangeOriginAmount.bind(this)}
        />
        <input type='hidden' value={this.sanitizeAmount(originAmount)} name={this.props.originAmount.name} />
        <span className="help-block">El monto mínimo para transferir {this.minAmountHelpText()}</span>
        {this.drawAmountIconStatus()}
      </div>
    )
  }

  sanitizeAmount(amount) {
    if (typeof amount == 'string'){
      return parseFloat(
        amount.replaceAll(',','')
          .replaceAll(this.originCurrency())
          .match(/[\d\.]+/)
      );
    }
    return amount;
  }

  getLabelDestinationAmount() {
    switch(this.state.type){
      case 'TransferVenChl': return '¿Cuántos Pesos necesitas?';
      default: return '¿Cuántos Bolívares necesitas?';
    }
  }

  getLabelOriginAmount() {
    switch(this.state.type){
      case 'TransferEurVen': return 'Euros a cambiar';
      case 'TransferChlVen': return 'Pesos a cambiar';
      case 'TransferPerVen': return 'Soles a cambiar'; 
      case 'TransferVenChl': return 'Bolívares a cambiar';
    }
  }

  drawCurrencyInputs() {
    return(
      <div>
        {this.drawInputoriginAmount()}
        {this.drawInputdestinationAmount()}
      </div>
    )
  }

  drawCurrencyForm() {
    var form_attributes = this.props.transfer_attributes;
    if (form_attributes){
      switch (this.state.type){
        case 'TransferVenChl': return (<ChileanForm {...form_attributes} />); break;
        case 'TransferEurVen': return (<EuropeanForm {...form_attributes} />); break;
        default: return (<VenezuelanForm {...form_attributes} />); break;
      }
    }
  }

  render() {
    this.swithSubmitButton();
    return (
      <div>
        {this.drawCardinality()}
        {this.drawCurrencyInputs()}
        {this.drawCurrencyForm()}
      </div>
    )
  }
};
