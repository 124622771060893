import React, { Component } from 'react'
import LazyComponent from './lazy_component'
import numeral from 'numeral'
import CancelButtonLbTrade from './buttons_trades'

export default class CalculatorBtcTransfer extends LazyComponent {
  constructor(props) {
    super(props);
    var bankingAccountId = this.props.transferType == 'TransferLot' ? this.props.bankingAccounts[0].id : null;
    var defaultMarket = this.defaultMarket().orderBook;

    this.state = {
      analisys: this.props.analisys,
      orderBookMarket: defaultMarket,
      bankingAccountId: bankingAccountId,
      sbOrderBook: this.props.sbOrderBook,
      cexOrderBook: this.props.cexOrderBook,
      lbOrderBook: this.props.lbOrderBook,
      krakenOrderBook: this.props.krakenOrderBook,
      sellAds: this.props.sellAds,
      originAmount: this.props.originAmount,
      destinationAmount: this.props.destinationAmount,
      sbFee: this.props.sbFee,
      cexFee: this.props.cexFee,
      krakenFee: this.props.krakenFee,
      lbFee: this.props.lbFee,
      priceType: this.props.priceType || 'market',
      orderPrice: null,
      executionOrders: this.props.executionOrders || [],
      venezuelanBanks: this.props.venezuelanBanks || [],
      bankingAccounts: this.props.bankingAccounts || []
    }
  }

  getFee() {
    var orderBookMarket = this.state.orderBookMarket;
    var fee = null;
    switch(orderBookMarket){
      case 'sbOrderBook': fee = this.state.sbFee; break;
      case 'cexOrderBook': fee = this.state.cexFee; break;
      case 'krakenOrderBook': fee = this.state.krakenFee; break;
      case 'lbOrderBook': fee = this.state.lbFee; break;
    }
    return fee;
  }

  calcFee() {
    var fee = this.getFee().value;
    return (fee / (1 - fee));
  }

  newTrades() {
    return this.state.sellAds.filter(function (value) {
      return value.amount_vef
    });
  }

  handleSubmit() {
    var _this = this;
    var estimatedBtcPrice = eval(this.estimateBtcPrice());
    var data = {
      execution_order: {
        estimated_btc_price: estimatedBtcPrice.toDecimalCurrency(),
        price_type: this.state.priceType,
        fee_id: this.getFee().id,
        banking_account_id: this.state.bankingAccountId,
        lb_trades_attributes: this.newTrades().map(function (sell_ad) {
          return {
            lb_sell_ad_id: sell_ad.id,
            amount_vef: sell_ad.amount_vef,
            estimated_btc_amount: sell_ad.estimated_btc_amount
          }
        })
      },
      authenticity_token: _this.props.csrfToken
    }
    $.ajax({
      type: "POST",
      url: this.props.executionOrderPath,
      data: data,
      success: function (response) {
        // Si el success se saca como una funcion del componente
        // ocurre un problema con la actualización de la ganancia
        // ya que la limpieza de los campos ingresados ocurre
        // no surte efecto y hace como que si los campos quedaron
        // con valores
        if(response.id){
          _this.state.sellAds = _this.state.sellAds.map(function (sellAd) {
            delete sellAd['amount_vef'];
            delete sellAd['estimated_btc_amount'];
            return sellAd
          })
          _this.state.executionOrders.push(response)
          _this.setState(_this.state)
        }
      }
    });
  }

  calculateEstimateBtc(event) {
    var index = event.target.dataset.index;
    var amount = numeral(event.target.value).value() || 0;
    var price = numeral(event.target.dataset.price).value();
    var btc = (amount / price).toBtc();
    this.state.sellAds[index]['amount_vef'] = amount;
    this.state.sellAds[index]['estimated_btc_amount'] = btc;
    this.setState(this.state);
  }

  totalGrossBtc(){
    return this.state.sellAds.reduce(function (value, sellAd) {
      return value + (sellAd.estimated_btc_amount || 0);
    }, 0).toBtc();
  }

  totalBtcFee() {
    return (this.totalGrossBtc() * this.calcFee()).toBtc();
  }

  totalBtc() {
    return (this.totalGrossBtc() + this.totalBtcFee()).toBtc();
  }

  renderBtcLegend() {
    var totalBtc = this.totalBtc();
    var totalGrossBtc = (totalBtc / (1 + this.calcFee())).toBtc();
    var fee = (totalGrossBtc * this.calcFee()).toBtc();
    return(
      <small>
        {totalGrossBtc + " + " + fee + " = " + totalBtc}
      </small>
    )
  }

  getOrderBook() {
    var orderBookMarket = this.state.orderBookMarket || 'sbOrderBook';
    return this.state[orderBookMarket];
  }

  estimateBtcPrice() {
    if (this.state.priceType == 'limit'){
      return this.state.orderPrice;
    }
    var asks = this.getOrderBook().asks;
    var totalBtc = this.totalBtc();
    var totalCovered = 0;
    var orderToDo = [];
    var index = 0;
    while(totalCovered < totalBtc) {
      var totalUnCovered = totalBtc - totalCovered;
      var orderPrice = eval(asks[index][0]);
      var orderAmount = eval(asks[index][1])
      var amount = (totalUnCovered <= orderAmount) ? totalUnCovered : orderAmount;
      orderToDo.push([orderPrice, amount])
      totalCovered += amount;
      index++;
    };
    return orderToDo.reduce(function (value, order) {
      orderAmount = eval(order[1]);
      orderPrice = eval(order[0]);
      return value + (orderPrice * (orderAmount / totalBtc))
    }, 0);
  }

  totalOrigin() {
    return (this.totalBtc() * this.estimateBtcPrice()).toDecimalCurrency();
  }

  renderOriginLegend() {
    var total = this.totalOrigin();
    var totalOrigin = (total / (1 + this.calcFee())).toDecimalCurrency(2);
    var fee = (totalOrigin * this.calcFee()).toDecimalCurrency(2);
    return(
      <small>
        {totalOrigin + " + " + fee + " = " + numeral(total).format('0,0.00')}
      </small>
    )
  }

  totalBs() {
    return this.state.sellAds.reduce(function (value, sellAd) {
      return value + (sellAd['amount_vef'] || 0);
    }, 0);
  }

  renderVefLegend(){
    return(
      <small>
        {"Resta: " + numeral(this.remainingDestinationAmount()).format('0,0.00')}
      </small>
    )
  }

  isSumiteable() {
    var _this = this;
    var all_trades_valid = this.newTrades().reduce(function (value, trade) {
      return value && _this.isValidSellAdAmount(trade.amount_vef, trade)
    }, true)
    return all_trades_valid && this.totalBs() > 0 && this.remainingDestinationAmount() >= 0;
  }

  renderSubmitButton() {
    if(this.isSumiteable()){
      return(
        <button className='btn btn-success' onClick={this.handleSubmit}>
          Procesar
        </button>
      )
    }
  }

  getAllTrades() {
    return this.state.executionOrders.reduce(function (value, executionOrder) {
      return value.concat(executionOrder.lb_trades);
    }, []);
  }

  getValidTrades() {
    return this.state.executionOrders.reduce(function (value, executionOrder) {
      var trades = executionOrder.lb_trades.filter(function (trade) {
        return trade.aasm_state != 'anulado'
      });
      return value.concat(trades);
    }, []);
  }

  remainingDestinationAmount() {
    return this.state.destinationAmount - this.getValidTrades()
      .reduce(function (value, trade) {
        return value + trade.amount_vef;
      }, 0) - this.totalBs();
  }

  totalProfit() {
    var _this = this;
    var spent = this.state.executionOrders.reduce(function (value, executionOrder) {
      return value + (executionOrder.lb_trades.reduce(function (_value, trade) {
        var totalBtcAmount = (trade.aasm_state != 'anulado' ?
          (eval(trade.estimated_btc_amount) * (1 + executionOrder.fee_value)) : 0);
        return _value + totalBtcAmount
      }, 0) * eval(executionOrder.estimated_btc_price));
    }, 0)
    return this.state.originAmount - spent - this.totalOrigin();
  }

  isValidSellAdAmount(amount, sellAd) {
    if(amount == '')
      return null;
    return amount >= sellAd.min_amount && (amount <= sellAd.max_amount || sellAd.max_amount == null);
  }

  classForSellAd(amount, sellAd) {
    switch(this.isValidSellAdAmount(amount, sellAd)){
      case true: return 'success';
      case false: return 'danger';
      default: return '';
    }
  }

  classForTotalBs(amount, sellAd) {
    var remaining = this.remainingDestinationAmount();
    if(remaining < 0)
      return 'text-danger';
    else if (remaining > 0)
      return 'text-primary';
    else
      return 'text-success';
  }

  renderSellAds() {
    var _this = this;
    return(
      <table className='table table-striped'>
        <thead>
          <tr>
            <th>Comprar Bs</th>
            <th>Precio</th>
            <th>Min</th>
            <th>Max</th>
            <th>Aviso</th>
            <th>Banco</th>
          </tr>
        </thead>
        <tbody>
          {this.state.sellAds.map(function (sellAd, index) {
            var tempPrice = numeral(sellAd.temp_price).format('0,0.00');
            var minAmount = numeral(sellAd.min_amount).format('0,0.00');
            var maxAmount = numeral(sellAd.max_amount).format('0,0.00');
            var amountVef = sellAd.amount_vef || '';
            var providerName = sellAd.username;
            return(
              <tr key={"lb_ad_"+index} className={_this.classForSellAd(amountVef, sellAd)}>
                <td>
                  <input type='text' className='form-control amount_vef'
                    tabIndex={index+1}
                    value={amountVef}
                    data-index={index}
                    data-price={tempPrice}
                    data-min={minAmount}
                    data-max={maxAmount}
                    onChange={_this.calculateEstimateBtc}/>
                </td>
                <td>{tempPrice}</td>
                <td>{minAmount}</td>
                <td>{maxAmount}</td>
                <td>
                  <a href={sellAd.public_view} target='_blank'>
                    {providerName}
                  </a>
                </td>
                <td className='text-break-word'>
                  {sellAd.bank_name}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  renderExecutedOrders() {
    var _this = this;
    return(
      <div className='panel panel-default'>
        <div className='panel-heading'>
          Ordenes ejecutadas
        </div>
        <div className='panel-body table-responsive'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th></th>
                <th>Estado</th>
                <th>Comprar Bs</th>
                <th>BTC estimado</th>
                <th>Aviso</th>
                <th>banco</th>
              </tr>
            </thead>
            <tbody>
              {this.getAllTrades().map(function (trade, index) {
                return(
                  <tr key={"trade_"+index}>
                    <td>
                      <CancelButtonLbTrade
                          lb_trade={trade}
                          csrfToken={_this.props.csrfToken} />
                    </td>
                    <td>{trade.aasm_state}</td>
                    <td>{numeral(trade.amount_vef).format('0,00.00')}</td>
                    <td>{eval(trade.estimated_btc_amount || -1).toBtc()}</td>
                    <td>
                      <a href={trade.lb_sell_ad_public_view} target='_blank'>
                        {trade.lb_sell_ad_username}
                      </a>
                    </td>
                    <td className='text-break-word'>
                      {trade.lb_sell_ad_bank_name}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderNewAnalisys() {
    return(
        <div className='panel panel-default'>
          <div className='panel-heading'>
            <span>Analisis de mercado</span>
          </div>
          <div className='panel-body'>
            <form action={this.props.newAnalisysBtcPath} method='POST'>
              <input type='hidden' name='authenticity_token' value={this.props.csrfToken} />
              <div className='form-group'>
                <select name='analisys_btc_transfer[bank_ids][]' className='form-control selectized' multiple={true}>
                  {this.state.venezuelanBanks.map(function (venezuelanBank, index) {
                    return(
                      <option key={'venezuelan_bank'+index} value={venezuelanBank.id} key={'venezuelanBank-' + index}>{venezuelanBank.name}</option>
                    )
                  })}
                </select>
              </div>
              <input type='submit'
                 className='btn btn-success pull-right'
                 data-loading-text="Creando nuevo Analisis..."
                 value='Nuevo Analisis BTC'/>
            </form>
          </div>
        </div>
    )
  }

  componentDidMount() {
    $('.selectized').selectize({});
  }

  changeOrderBookMarket(event) {
    this.setState({orderBookMarket: event.target.value});
  }

  changeBankingAccountId(event) {
    this.setState({bankingAccountId: event.target.value});
  }

  marketOptions() {
    var options = null;
    switch(this.props.transferType){
      case 'TransferEurVen': options = [{orderBook: 'krakenOrderBook', name: 'krake.io', default: true}]; break;
      case 'TransferChlVen': options = [{orderBook: 'sbOrderBook', name: 'buda.com', default: true}, {orderBook: 'cexOrderBook', name: 'cex.io'}, {orderBook: 'lbOrderBook', name: 'Localbitcoin'}]; break;
      case 'TransferPerVen': options = [{orderBook: 'sbOrderBook', name: 'buda.com', default: true}]; break;
    }
    if (options == null && this.props.transferLotTypes == ['TransferEurVen']){
       return [{orderBook: 'krakenOrderBook', name: 'krake.io', default: true}];
    }else{
      return options || [{orderBook: 'sbOrderBook', name: 'buda.com', default: true}, {orderBook: 'lbOrderBook', name: 'Localbitcoin'}];
    }
  }

  defaultMarket() {
    var options = this.marketOptions();
    var defaultMarket = options.find(function (option) {
      return option.default;
    });
    return defaultMarket;
  }

  renderMarketOptions() {
    var options = this.marketOptions();
    return(
      <div>
        <label>Mercado compra BTC</label>
        <select defaultValue={this.state.orderBookMarket}
                className='form-control'
                onChange={this.changeOrderBookMarket}>
          {options.map(function (marketOption, index) {
            return (
              <option key={'marketOption' + index}
                      value={marketOption.orderBook}>
                {marketOption.name}
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  renderClientBankingAccountOption() {
    if (this.props.transferType != 'TransferLot'){
      return(
        <option value=''>Cuenta del cliente</option>
      )
    }
  }

  renderBankingAccount() {
    return(
      <div>
        <label>Cuenta Destino</label>
        <select defaultValue={this.state.bankingAccountId}
                className='form-control'
                onChange={this.changeBankingAccountId}>
          {this.renderClientBankingAccountOption()}
          {this.state.bankingAccounts.map(function (account, index) {
            return (
              <option key={"bank_account_option"+index} value={account.id}>
                {account.name_of_bank}-
                {account.holder_name}-
                {account.account_type}
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  renderAnalisysLegend() {
    if(this.state.analisys){
      return(
        <small className='text-danger'>
          (Análisis de mercado hecho{' '}
          <strong>{moment(this.state.analisys.created_at).startOf('minute').fromNow()}</strong>)
        </small>
      )
    }else{
      return(
        <small className='text-danger'>
          (No se ha hecho análisis)
        </small>
      )
    }
  }

  handlerPriceType(event) {
    var attrs = {priceType: event.target.value};
    if (event.target.value == 'limit'){
      var orderBook = this.getOrderBook();
      var orderPrice = this.state.orderPrice || (orderBook.bids ? orderBook.bids[0][0] : 0);
      attrs["orderPrice"] = orderPrice
    }
    this.setState(attrs);
  }

  handlerOrderPrice(event) {
    this.setState({orderPrice: event.target.value});
  }

  renderOrderPriceInput() {
    var disabled = this.state.priceType == 'market' ? true : false;
    if (disabled){
      return(
        <input value={this.estimateBtcPrice()} className='form-control' disabled={disabled}/>
      )
    }else{
      return(
        <input type='number' value={this.state.orderPrice} onChange={this.handlerOrderPrice} className='form-control'/>
      )
    }
  }

  renderAdvancedOptions() {
    var orderBook = this.getOrderBook();
    var askOrders = orderBook.asks ? orderBook.asks.slice(0, 3) : [];
    var bidOrders = orderBook.bids ? orderBook.bids.slice(0, 3) : [];
    var askPrice = eval(askOrders[0] ? askOrders[0][0] : 0);
    var bidPrice = eval(bidOrders[0] ? bidOrders[0][0] : 0);
    var spread = askPrice - bidPrice;
    var spreadPercentage = (1 - (bidPrice / askPrice)) * 100;
    var originCurrency = this.props.rateConfig.origin_sym;

    return(
      <div collapselassname='row'>
        <div className='col-sm-12'>
          <a role="button" data-toggle="collapse" href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            Opciones Avanzadas
          </a>
          <div className="collapse well" style={{marginTop: '10px'}} id="collapseExample">
            <div className="row">
              <div className='col-sm-4'>
                <div className="form-group">
                  <label>Tipo de Precio</label>
                  <select className='form-control' value={this.state.priceType} onChange={this.handlerPriceType}>
                    <option value='market'>Market</option>
                    <option value='limit'>Limit</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Precio {originCurrency}</label>
                  {this.renderOrderPriceInput()}
                </div>
              </div>
              <div className='col-sm-8'>
                <div className='row'>
                  <div className='col-sm-12 text-center'>
                    <h4>Spread {numeral(spread).format('0,0.00')}{originCurrency} ({numeral(spreadPercentage).format('0,0.00')}%)</h4>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <strong>Asks</strong>
                    <ul>
                      {askOrders.map(function (ask, index) {
                        return <li key={'ask_option'+index}>[{ask[1]}, {numeral(ask[0]).format('0,0.00')}]</li>
                      })}
                    </ul>
                  </div>
                  <div className='col-sm-6'>
                    <strong>Bids</strong>
                    <ul>
                      {bidOrders.map(function (bid, index) {
                        return <li key={'bid_option'+index}>[{bid[1]}, {numeral(bid[0]).format('0,0.00')}]</li>
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    var _this = this;
    var originCurrency = this.props.rateConfig.origin_sym;
    return(
      <div>
        {this.renderExecutedOrders()}
        {this.renderNewAnalisys()}
        <div className='panel panel-default table-responsive'>
          <div className='panel-heading'>
            <span>
              Nueva orden de ejecución{' '}
              {this.renderAnalisysLegend()}
            </span>
          </div>
          <div className='panel-body'>
            <div className='row text-center'>
              <div className='col-sm-3'>
                <div className='form-group'>
                  <label>Bolivares por enviar</label>
                  <h5>{numeral(this.remainingDestinationAmount()).format('0,00.00')}</h5>
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='form-group'>
                  <label>{originCurrency + 'recibido'}</label>
                  <h5>{numeral(this.state.originAmount).format('0,00.00')}</h5>
                </div>
              </div>
              <div className='col-sm-3'>
                {this.renderMarketOptions()}
              </div>
              <div className='col-sm-3'>
                {this.renderBankingAccount()}
              </div>
            </div>

            <hr/>
            <div className='row text-center'>
              <div className='col-xs-6 col-md-3'>
                <div className='form-group'>
                  <label>Bitcoins a Gastar</label>
                  <h3 style={{marginTop: 0}}>{this.totalBtc()}</h3>
                  {this.renderBtcLegend()}
                </div>
              </div>
              <div className='col-xs-6 col-md-3'>
                <div className='form-group'>
                  <label>{originCurrency + 'a Gastar'}</label>
                  <h3 style={{marginTop: 0}}>{numeral(this.totalOrigin()).format('0,0.00')}</h3>
                  {this.renderOriginLegend()}
                </div>
              </div>
              <div className='col-xs-6 col-md-3'>
                <div className={'form-group ' + this.classForTotalBs()}>
                  <label>Bs a enviar</label>
                  <h3 style={{marginTop: 0}}>{numeral(this.totalBs()).format('0,0.00')}</h3>
                  {this.renderVefLegend()}
                </div>
              </div>
              <div className='col-xs-6 col-md-3'>
                <div className='form-group'>
                  <label>{'Total Ganancia ' + originCurrency}</label>
                  <h3 style={{marginTop: 0}}>{numeral(this.totalProfit()).format('0,0.00')}</h3>
                </div>
              </div>
            </div>

            {this.renderAdvancedOptions()}

            <div className='row text-center'>
              <div className='col-xs-12'>
                {this.renderSubmitButton()}
              </div>
            </div>
            <hr/>
            <div className='table-responsive'>
              {this.renderSellAds()}
            </div>

          </div>
        </div>
      </div>
    )
  }
}
